// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise';
import 'core-js/es6/string';
import 'core-js/es7/array';
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuex from 'vuex';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VeeValidate from 'vee-validate';
import App from './App';
import router from './router';
import store from './store';
import IdleVue from 'idle-vue';
import * as VueGoogleMaps from "vue2-google-maps";
import vSelect from 'vue-select';
import { firestorePlugin } from 'vuefire'
import './filters';
import JsonViewer from 'vue-json-viewer';
import VueImgOrientationChanger from 'vue-img-orientation-changer';
import VueQRCodeComponent from 'vue-qrcode-component'

import Toaster from 'v-toaster';

// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'v-toaster/dist/v-toaster.css';

import config from '@/config/env-constants';
import _ from 'lodash';

// Custom Plugins
import Geolocation from './plugins/geolocation';


Vue.use(Vuex);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('v-select', vSelect);
Vue.use(VeeValidate, {
	fieldsBagName: 'veeFields'
});
Vue.use(VueAxios, axios);
Vue.use(Toaster, {
	timeout: 5000
});
Vue.use(firestorePlugin);

// for 15 minutes of inactivity logout
const eventsHub = new Vue();
Vue.use(IdleVue, {
	eventEmitter: eventsHub,
	idleTime: 600000
});

Vue.use(VueGoogleMaps, {
	load: {
		key: config.googleMapKey[config.currEnv],
		libraries: "places" // necessary for places input
	}
});

Vue.use(JsonViewer);
Vue.use(Geolocation);
Vue.use(VueImgOrientationChanger);

// For QRCode Generation
Vue.component('qr-code', VueQRCodeComponent)

/* eslint-disable no-new */
new Vue({
	el: '#app',
	router,
	store,
	template: '<App/>',
	components: {
		App
	},
	async onIdle() {
		if (this.$store.getters.isAuthenticated && !_.isEmpty(localStorage.getItem('email'))) {
			this.$toaster.warning("You have been logged out due to inactivity. Please login again to access the system");
			await this.$store.dispatch('logout');
			let previousState = this.$store.getters.previousState;
			if (!_.isEmpty(previousState)) {
				this.$store.replaceState(previousState);
			}

			this.$router.push("/login");
		}
	}
});

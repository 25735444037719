import _ from 'lodash';

export default {
    namespace: true,

    state: {
        assetPoolDistributions: {},
        connectedAssetPoolDistributions: {},
        currAssetPoolDistribution: {},
    },

    mutations: {
        SET_ALL_ASSET_POOL_DISTRIBUTIONS(state, assetPoolDistributions) {
            state.assetPoolDistributions = assetPoolDistributions;
        },
        SET_CONNECTED_ASSET_POOL_DISTRIBUTIONS(state, assetPoolDistributions) {
            state.connectedAssetPoolDistributions = assetPoolDistributions;
        },
        SET_CURR_INVENTORY_COUNT(state, currAssetPoolDistribution) {
            state.currAssetPoolDistribution = currAssetPoolDistribution;
        }
    },

    actions: {
        setAllAssetPoolDistributions(vuexContext, assetPoolDistributions) {
            vuexContext.commit('SET_ALL_ASSET_POOL_DISTRIBUTIONS', assetPoolDistributions);
        },
        setAllConnectedAssetPoolDistributions(vuexContext, assetPoolDistributions) {
            vuexContext.commit('SET_CONNECTED_ASSET_POOL_DISTRIBUTIONS', assetPoolDistributions);
        },
        updateAllAssetPoolDistributions(vuexContext, newAssetPoolDistributions) {
			let assetPoolDistributions = vuexContext.getters.assetPoolDistributions;

			_.forEach(newAssetPoolDistributions, (value, key) => {
				assetPoolDistributions[key] = value;
			});

			vuexContext.commit('SET_ALL_ASSET_POOL_DISTRIBUTIONS', assetPoolDistributions);
		},
    },

    getters: {
        assetPoolDistributions(state) {
            if (!_.isEmpty(state.assetPoolDistributions)) {
                return state.assetPoolDistributions;
            }
            return {};
        },
        currAssetPoolDistribution(state) {
            if (!_.isEmpty(state.currAssetPoolDistribution)) {
                return state.currAssetPoolDistribution;
            }
            return {};
        },
    }
}
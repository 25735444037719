export default {
	currEnv: 'uat3',
	timeout: 1000,
	view: {
		ADMIN: "Admin",
		COMPANY: "Company"
	},
	batchStatus: {
		IN_PRODUCTION: {
			name: "In Production",
			nextStatus: "For Checking",
			actionLabel: "Check"
		},
		IN_QC: {
			name: "In QC",
			nextStatus: "For Endorsement",
			actionLabel: "Endorse"
		},
		IN_WAREHOUSE: {
			name: "In Warehouse",
			nextStatus: "Done",
			actionLabel: ""
		},
		CANCELLED: {
			name: "Cancelled",
			nextStatus: "",
			actionLabel: ""
		},
	},
	requestSource: {
		CMS: 'CMS',
		DISPATCH_RECEIPT: 'Dispatch Receipt',
		TRANSPORT: 'Transport',
	},
	firebaseAuthLink: 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=',
	googleMapKey: {
		dev: 'AIzaSyDlxV-D7owLEVycJ3P236MwXf-OwD9I1gs',
		qa: 'AIzaSyBC6jFJLf7Tu2j7McaaO8uQjK9cc5kx6Ts',
		uat: 'AIzaSyC1JhiYi-C8WHNQOTIf6eYTnTBNNbiMLqk',
		uat2: 'AIzaSyBbludbI5ynMTNyeCsPe-q5Sc7kNfu7frM',
		uat3: 'AIzaSyDk_qgNwD1NbK669X1EHCDHimEJcpYmT4Q',
		preprod: 'AIzaSyAGQDezWvCoKSZ3JF1VJKNz_p6iqYY8Bpk',
		prod: 'AIzaSyBBdrDnlRhnbP2wAXpCX8IiGSRNum0sits'
	},
	adminAccount: {
		TAWI_SUPPORT: 'support@tawitech.ph'
	},
	managerRole: 'Manager',
	supervisorRole: 'Supervisor',
	viewerRole: 'Viewer',
	superAdminRole: 'Super Admin',
	passwordRegex: /[A-Za-z0-9<>()\.,\"\'=:;@$%*?!&_\-+|]{8,}/,
	passwordErrorMsg: 'Password must have at least eight (8) characters and should contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
	maxBatchSize: 400,
	companyTypes: [{
		value: null,
		text: ' - Please select - '
	},
	{
		value: 'Asset User',
		text: 'Asset User'
	},
	{
		value: 'Asset Service Provider',
		text: 'Asset Service Provider'
	},
	{
		value: 'Hybrid',
		text: 'Hybrid'
	}
	],
	assetStatus: [{
		value: null,
		text: ' - Please select - '
	},
	{
		value: 'Inactive',
		text: 'Inactive'
	},
	{
		value: 'Stored',
		text: 'Stored'
	}, {
		value: 'In-Transit',
		text: 'In-Transit'
	}, {
		value: 'Lost',
		text: 'Lost'
	}
	],
	changeLocationPurpose: [{
		value: null,
		text: ' - Please select - '
	},
	{
		value: 'Wrong Input',
		text: 'Wrong Input'
	},
	{
		value: 'Unscanned Assets',
		text: 'Unscanned Assets'
	}, {
		value: 'Others',
		text: 'Others'
	}
	],
	dispatchStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Draft',
			text: 'Draft'
		},
		{
			value: 'In-Transit',
			text: 'In-Transit'
		},
		{
			value: 'Receiving',
			text: 'Receiving'
		},
		{
			value: 'Received',
			text: 'Received'
		},
		{
			value: 'Cancelled',
			text: 'Cancelled'
		},
	],
	receiptStatus: [
		{
			value: 'In-Transit',
			text: 'In-Transit'
		},
		{
			value: 'Receiving',
			text: 'Receiving'
		},
		{
			value: 'Received',
			text: 'Received'
		},
		{
			value: 'Pending',
			text: 'Pending'
		}
	],
	maintenanceStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Open',
			text: 'Open'
		},
		{
			value: 'In-Repair',
			text: 'In-Repair'
		},
		{
			value: 'Closed',
			text: 'Closed'
		},
		{
			value: 'Cancelled',
			text: 'Cancelled'
		}
	],
	assetConditionStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Good',
			text: 'Good'
		},
		{
			value: 'Damaged',
			text: 'Damaged'
		}
	],
	assetHistoryOperation: {
		CREATE_NEW_ASSET: 'CREATE_NEW_ASSET',
		ACTIVATE_ASSET: 'ACTIVATE_ASSET',
		CHANGE_ASSET_LOCATION: 'CHANGE_ASSET_LOCATION',
		MARK_ASSET_AS_LOST: 'MARK_ASSET_AS_LOST',
		DISPATCH_ASSET: 'DISPATCH_ASSET',
		CANCEL_DISPATCH_ASSET: 'CANCEL_DISPATCH_ASSET',
		RECEIVE_ASSET: 'RECEIVE_ASSET',
		IMPORT_ASSET: 'IMPORT_ASSET',
		ASSET_INVENTORY: 'ASSET_INVENTORY'
	},
	assetTagging: {
		DEFAULT: 'Default',
		CUSTOM: 'Custom'
	},
	assetIssuanceStatus: {
		ON_GOING: 'On-Going',
		RETURNED: 'Returned',
		CANCELLED: 'Cancelled'
	},
	statusOptions: [
		{ text: 'Active', value: "true" },
		{ text: 'Inactive', value: "false" },
	],
	statusOptionsWithDefault: [
		{ text: ' - Please select - ', value: null },
		{ text: 'Active', value: "true" },
		{ text: 'Inactive', value: "false" },
	],
	requestStatusOptions: [
		{ text: ' - Please select - ', value: null },
		{ text: 'Open', value: 'Open' },
		{ text: 'Approved', value: 'Approved' },
		{ text: 'Rejected', value: 'Rejected' },
	],
	assetTaggingOptions: [
		{ text: 'Default', value: "Default" },
		{ text: 'Custom', value: "Custom" },
	],
	companyDefaultValue: {
		id: null,
		isActive: null,
		name: ' - Please select - ',
		type: null,
		brand: null,
		text: ' - Please select - '
	},
	userTypeDefaultValue: {
		value: null,
		text: ' - Please select - '
	},
	industryDefaultValue: {
		value: null,
		text: ' - Please select - '
	},
	storageLocationDefaultValue: {
		id: null,
		name: ' - Please select - ',
		geoaddress: null,
		companyId: null,
		text: ' - Please select - '
	},
	transportationDefaultValue: {
		id: null,
		name: ' - Please select - ',
		company: null,
		companyId: null,
		hasCheckingInterval: false,
		checkingInterval: 0
	},
	transportationOthersValue: {
		id: null,
		name: ' - Others - ',
		company: null,
		companyId: null,
		hasCheckingInterval: false,
		checkingInterval: 0
	},
	assetTypeDefaultValue: {
		id: null,
		name: ' - Please select - ',
		origin: null,
		originId: null,
		text: ' - Please select - '
	},
	assetTypeCodeDefaultValue: {
		id: null,
		name: ' - Please select - ',
		prefix: null,
		text: ' - Please select - '
	},
	userDefaultValue: {
		id: null,
		name: ' - Please select - ',
		companyId: null,
		type: null,
		text: ' - Please select - '
	},
	driverDefaultValue: {
		id: null,
		name: ' - Please select - ',
		company: null,
		companyId: null,
		assistants: null,
		isAccountable: false,
		license: {}
	},
	incidentTypeDefaultValue: {
		value: null,
		text: ' - Please select - ',
	},
	driverOtherValue: {
		id: null,
		name: ' - Others - ',
		company: null,
		companyId: null,
		assistants: null,
		isAccountable: false,
		license: {}
	},
	dispatchCreationSource: {
		DISPATCH: 'Dispatch',
		RECEIPT: 'Receipt',
		PUSHPULL: 'PushPull'
	},
	dispatchInputAssetLogDefaultValue: {
		dispatch: { scan: [], manual: [], auto: [] },
		receipt: { scan: [], manual: [], auto: [] }
	},
	inputAssetLogDefaultValue: { scan: [], manual: [], auto: [] },
	notificationType: {
		LOCKED_ACCOUNT: 'LOCKED_ACCOUNT',
		NEW_COMPANY: 'NEW_COMPANY',
		DISPATCH_DEPLOYED: 'DISPATCH_DEPLOYED',
		DISPATCH_RECEIVED: 'DISPATCH_RECEIVED',
		DISPATCH_IN_TRANSIT: 'DISPATCH_IN_TRANSIT',
		CHANGE_LOCATION: 'CHANGE_LOCATION',
		INCIDENT_REPORT: 'INCIDENT_REPORT'
	},
	permissionsDefaultValues: {
		cms: true,
		dispatch: true,
		transport: true,
		maintenance: true,
	},
	userTypes: [
		{ value: null, text: ' - Please select - ' },
		{ value: 'Manager', text: 'Manager' },
		{ value: 'Supervisor', text: 'Supervisor' },
		{ value: 'Scanner', text: 'Scanner' },
		{ value: 'Viewer', text: 'Viewer' },
		{ value: 'Driver', text: 'Driver' },
	],
	maxQRCodeStrLength: 30
};

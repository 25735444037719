
import _ from 'lodash';

export default {
	namespace: true,

	state: {
		maintenances: {},
		currMaintenance: {},
		maintenanceParams: {}
	},

	mutations: {
		SET_ALL_MAINTENANCES(state, maintenances) {
			state.maintenances = maintenances;
		},
		SET_MAINTENANCE_PARAMS(state, maintenanceParams) {
			state.maintenanceParams = maintenanceParams
		},
		SET_CURR_MAINTENANCE(state, maintenance) {
			state.currMaintenance = maintenance
		}
	},

	actions: {
		setAllMaintenances(vuexContext, maintenances) {
			vuexContext.commit('SET_ALL_MAINTENANCES', maintenances);
		},

		updateAllMaintenances(vuexContext, newMaintenances) {
			let maintenances = vuexContext.getters.maintenances;

			_.forEach(newMaintenances, (value, key) => {
				maintenances[key] = value;
			});

			vuexContext.commit('SET_ALL_MAINTENANCES', maintenances);
		},

		setCurrentMaintenance(vuexContext, maintenance) {
			vuexContext.commit('SET_CURR_MAINTENANCE', maintenance);
		},

		setMaintenanceParams(vuexContext, params) {
			vuexContext.commit('SET_MAINTENANCE_PARAMS', params);
		}
	},

	getters: {
		maintenances(state) {
			if (!_.isEmpty(state.maintenances)) {
				return state.maintenances;
			}
			return {};
		},
		maintenanceParams(state) {
			if (state.maintenanceParams.allCompaniesObj) {
				return state.maintenanceParams;
			}
			return {};
		},
		currMaintenance(state) {
			if (state.currMaintenance.maintenanceId) {
				return state.currMaintenance;
			}
			return {};
		},
	}
}

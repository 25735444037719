import Vue from 'vue';
import Router from 'vue-router';

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer');
const AdminContainer = () => import('@/containers/AdminContainer');

// Common Views
const Page404 = () => import('@/views/commons/Page404');
const Page500 = () => import('@/views/commons/Page500');
const PrivacyPolicy = () => import('@/views/commons/PrivacyPolicy');
const Dashboard = () => import('@/views/commons/Dashboard');

// Account
const ResetPassword = () => import('@/views/account/ResetPassword');
const Login = () => import('@/views/account/Login');

// Account Setup
const Company = () => import('@/views/setup/Company');
const StorageLocation = () => import('@/views/setup/StorageLocation');
const Connection = () => import('@/views/setup/Connection');
const User = () => import('@/views/setup/User');
const Transportation = () => import('@/views/setup/Transportation');

// Quantity-Based
const AssetType = () => import('@/views/asset/AssetType');
const AssetPoolDistribution = () => import('@/views/asset/AssetPoolDistribution');


// Transactions
const Dispatch = () => import('@/views/transactions/Dispatch');
const AddDispatch = () => import('@/views/transactions/dispatch/AddDispatch');
const EditDispatch = () => import('@/views/transactions/dispatch/EditDispatch');
const Receipt = () => import('@/views/transactions/Receipt');
const AddMaintenance = () => import('@/views/transactions/maintenance/AddMaintenance');
const EditMaintenance = () => import('@/views/transactions/maintenance/EditMaintenance');
const RepairMaintenance = () => import('@/views/transactions/maintenance/RepairMaintenance');
const Maintenance = () => import('@/views/transactions/Maintenance');

// Reports
const InventorySummary = () => import('@/views/reports/InventorySummaryReport');
const AssetsWithTruckers = () => import('@/views/reports/AssetsWithTruckersReport');
const IncidentReport = () => import('@/views/reports/IncidentReport');
const AssetAccountability = () => import('@/views/reports/AssetAccountability');
const DispatchAlertLogs = () => import('@/views/reports/DispatchAlertLogs');

// Support
const TransferCompanyRequests = () => import('@/views/support/TransferCompanyRequests');

// Data Importer
const CompanyImporter = () => import('@/views/support/CompanyImporter');
const StorageLocationImporter = () => import('@/views/support/StorageLocationImporter');
const UserImporter = () => import('@/views/support/UserImporter');
const TransportationImporter = () => import('@/views/support/TransportationImporter');


Vue.use(Router);

export default new Router({
	mode: 'hash', // https://router.vuejs.org/api/#mode
	linkActiveClass: 'open active',
	scrollBehavior: () => ({
		y: 0
	}),
	routes: [
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/privacy-policy',
			name: 'Privacy Policy',
			component: PrivacyPolicy
		},
		{
			path: '/404',
			name: 'Page 404',
			component: Page404,
			alias: '*'
		},
		{
			path: '/500',
			name: 'Page 500',
			component: Page500
		},
		{
			path: '/reset-password',
			name: 'Reset Password',
			component: ResetPassword
		},
		{
			path: '/admin',
			redirect: '/admin/dashboard',
			name: 'Home',
			component: AdminContainer,
			children: [
				{
					path: 'dashboard',
					name: 'Dashboard',
					component: Dashboard
				},

				// Setup
				{
					path: 'company',
					name: 'Company',
					component: Company
				},
				{
					path: 'admin-location',
					name: 'Storage Location',
					component: StorageLocation
				},
				{
					path: 'admin-user',
					name: 'User',
					component: User
				},
				{
					path: 'admin-connection',
					name: 'Connection',
					component: Connection
				},
				{
					path: 'admin-transportation',
					name: 'Transportation',
					component: Transportation
				},

				// Asset Management
				{
					path: 'admin-asset-type',
					name: 'Asset Type',
					component: AssetType
				},
				{
					path: 'admin-asset-pool-distribution',
					name: 'Asset Pool Distribution',
					component: AssetPoolDistribution
				},

				// Transactions
				{
					path: 'admin-dispatch',
					name: 'Dispatch',
					component: Dispatch
				},
				{
					path: 'admin-add-dispatch',
					name: 'Add Dispatch',
					component: AddDispatch,
					props: true
				},
				{
					path: 'admin-edit-dispatch',
					name: 'Edit Dispatch',
					component: EditDispatch,
					props: true
				},
				{
					path: 'admin-receipt',
					name: 'Receipt',
					component: Receipt
				},
				{
					path: 'admin-maintenance',
					name: 'Maintenance',
					component: Maintenance
				},
				{
					path: 'admin-add-maintenance',
					name: 'Add Maintenance',
					component: AddMaintenance,
					props: true
				},
				{
					path: 'admin-edit-maintenance',
					name: 'Edit Maintenance',
					component: EditMaintenance,
					props: true
				},
				{
					path: 'admin-repair-maintenance',
					name: 'Repair Maintenance',
					component: RepairMaintenance,
					props: true
				},

				// Reports
				{
					path: 'admin-inventory-summary',
					name: 'Inventory Summary',
					component: InventorySummary
				},
				{
					path: 'admin-assets-with-truckers',
					name: 'Assets With Truckers',
					component: AssetsWithTruckers
				},
				{
					path: 'admin-dispatch-alerts',
					name: 'Dispatch Alerts',
					component: DispatchAlertLogs
				},
				{
					path: 'admin-incident-reports',
					name: 'Incident Reports',
					component: IncidentReport
				},
				{
					path: 'admin-asset-accountability',
					name: 'Asset Accountability',
					component: AssetAccountability
				},

				// Support 
				{
					path: 'admin-transfer-company-requests',
					name: 'Transfer Company Requests',
					component: TransferCompanyRequests
				},

				// Data Importer
				{
					path: 'company-importer',
					name: 'Company Importer',
					component: CompanyImporter
				},
				{
					path: 'storage-location-importer',
					name: 'Storage Location Importer',
					component: StorageLocationImporter
				},
				{
					path: 'user-importer',
					name: 'User Importer',
					component: UserImporter
				},
				{
					path: 'transportation-importer',
					name: 'Transportation Importer',
					component: TransportationImporter
				},
			]
		},
		{
			path: '/',
			redirect: '/dashboard',
			name: 'Home',
			component: DefaultContainer,
			children: [
				{
					path: 'dashboard',
					name: 'Dashboard',
					component: Dashboard
				},

				// Setup
				{
					path: 'company',
					name: 'Company',
					component: Company
				},
				{
					path: 'location',
					name: 'Storage Location',
					component: StorageLocation
				},
				{
					path: 'user',
					name: 'User',
					component: User
				},
				{
					path: 'connection',
					name: 'Connection',
					component: Connection
				},
				{
					path: 'transportation',
					name: 'Transportation',
					component: Transportation
				},

				// Asset Management
				{
					path: 'asset-type',
					name: 'Asset Type',
					component: AssetType
				},
				{
					path: 'asset-pool-distribution',
					name: 'Asset Pool Distribution',
					component: AssetPoolDistribution
				},

				// Transactions
				{
					path: 'dispatch',
					name: 'Dispatch',
					component: Dispatch
				},
				{
					path: 'add-dispatch',
					name: 'Add Dispatch',
					component: AddDispatch,
					props: true
				},
				{
					path: 'edit-dispatch',
					name: 'Edit Dispatch',
					component: EditDispatch,
					props: true
				},
				{
					path: 'receipt',
					name: 'Receipt',
					component: Receipt
				},
				{
					path: 'maintenance',
					name: 'Maintenance',
					component: Maintenance
				},
				{
					path: 'add-maintenance',
					name: 'Add Maintenance',
					component: AddMaintenance,
					props: true
				},
				{
					path: 'edit-maintenance',
					name: 'Edit Maintenance',
					component: EditMaintenance,
					props: true
				},
				{
					path: 'repair-maintenance',
					name: 'Repair Maintenance',
					component: RepairMaintenance,
					props: true
				},

				// Reports
				{
					path: 'inventory-summary',
					name: 'Inventory Summary',
					component: InventorySummary
				},
				{
					path: 'assets-with-truckers',
					name: 'Assets with Truckers',
					component: AssetsWithTruckers
				},
				{
					path: 'dispatch-alerts',
					name: 'Dispatch Alerts',
					component: DispatchAlertLogs
				},
				{
					path: 'incident-reports',
					name: 'Incident Reports',
					component: IncidentReport
				},
				{
					path: 'asset-accountability',
					name: 'Asset Accountability',
					component: AssetAccountability
				},

				// Support
				{
					path: 'transfer-company-requests',
					name: 'Transfer Company Requests',
					component: TransferCompanyRequests
				},

				// Data Importer
				{
					path: 'company-importer',
					name: 'Company Importer',
					component: CompanyImporter
				},
				{
					path: 'storage-location-importer',
					name: 'Storage Location Importer',
					component: StorageLocationImporter
				},
				{
					path: 'user-importer',
					name: 'User Importer',
					component: UserImporter
				},
				{
					path: 'transportation-importer',
					name: 'Transportation Importer',
					component: TransportationImporter
				},
			]
		}
	]
});

import Vuex from 'vuex';
import createPersistedState from "vuex-persist-indexeddb";

// list of modules
import auth from './modules/auth';
import users from './modules/users';
import companies from './modules/companies';
import storageLocations from './modules/storageLocations';
import assetTypeCategories from './modules/assetTypeCategories';
import uoms from './modules/uoms';
import incidentTypes from './modules/incidentTypes';
import assetTypes from './modules/assetTypes';
import connectionTypes from './modules/connectionTypes';
import connections from './modules/connections';
import transportations from './modules/transportations';
import dispatches from './modules/dispatches';
import maintenances from './modules/maintenances';
import notifications from './modules/notifications';
import assetPoolDistributions from './modules/assetPoolDistributions';
import companyAssetPools from './modules/companyAssetPools';
import notificationDispatchDetails from './modules/notificationDispatchDetails';
import notificationIncidentDetails from './modules/notificationIncidentDetails';
import dispatchAlertReports from './modules/dispatchAlertReports';

const createStore = () => {
	return new Vuex.Store({
		modules: {
			auth,
			users,
			companies,
			storageLocations,
			assetTypeCategories,
			assetTypes,
			uoms,
			incidentTypes,
			connectionTypes,
			connections,
			transportations,
			dispatches,
			maintenances,
			notifications,
			assetPoolDistributions,
			companyAssetPools,
			notificationDispatchDetails,
			notificationIncidentDetails,
			dispatchAlertReports
		},
		state: {},
		mutations: {},
		actions: {},
		getters: {},
		plugins: [createPersistedState()]
	});
};

export default createStore;

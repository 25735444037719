<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
import { Validator } from 'vee-validate';

export default {
	name: 'app',
	created() {
		Validator.extend('selectRequired', {
			getMessage: (field) => 'The ' + field + ' field is required',
			validate: (value) => value.name !== ' - Please select - ',
		});
	},
	mounted() {
		// Prevents history traversal from Login page to other pages
		window.onpopstate = () => {
			if (this.$route.path == '/login') {
				this.$router.push('/login');
			}
		};
	},
};
</script>

<style lang="scss">
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.min.css';
/* Import Font Awesome Icons Set */
$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/scss/font-awesome.scss';
/* Import Simple Line Icons Set */
$simple-line-font-path: '~simple-line-icons/fonts/';
@import '~simple-line-icons/scss/simple-line-icons.scss';
/* Import Flag Icons Set */
@import '~flag-icon-css/css/flag-icon.min.css';
/* Import Bootstrap Vue Styles */
@import '~bootstrap-vue/dist/bootstrap-vue.css';
// Import Main styles for this application
@import 'assets/scss/style';

@import './App.scss';

.help-block {
	color: #f44335;
	font-size: 12px;
	margin-bottom: 20px !important;
}

.input-with-validation {
	margin-top: 10px !important;
	margin-bottom: 0px !important;
}

ul ul a {
	font-size: 0.9em !important;
	padding-left: 30px !important;
}

// ------ Filter Option and Table Styles ------
.totalDisplay {
	line-height: 35px;
	font-weight: bold;
}

.btn-table-options {
	margin-top: 30px;
	margin-bottom: 10px;
}

.resetButton {
	margin-top: 32px;
}

.location-display {
	font-size: 11px !important;
}

.selectAllDisplay {
	line-height: 20px;
	font-weight: bold;
}

.addButton {
	margin-top: 27px;
}

.header {
	font-weight: bold;
	font-size: 16px;
}

.confirmMessage {
	margin: 10px;
}

.sidebar .nav {
	width: 208px;
	min-height: 100%;
}

a{
color: #F18F01 !important;
}
a:active {
  color:#122C91 !important;
}

.form-row > .col,
.form-row > [class*='col-'] {
	padding-right: 20px;
	padding-left: 5px;
}

.resetButton {
	margin-top: 28px;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
	width: 100%;
	padding-top: 0.1rem;
	padding-bottom: 0.5rem;
	font-size: 0.875rem;
	font-weight: 400;
	color: #5c6873;
	background-color: #fff;
	border: 1px solid #e4e7ea;
	border-radius: 0.25rem;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

div.v-toaster > span > div {
	word-wrap: break-word;
}
</style>

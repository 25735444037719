import firebase from 'firebase/app';


export const LocationUtil = {
    getLatitude: (geoaddress) => {
        let latitude = 0;

        if (geoaddress.latitude) {
            latitude = parseFloat(geoaddress.latitude);
        } else if (geoaddress._latitude) {
            latitude = parseFloat(geoaddress._latitude);
        } else if (geoaddress._lat) {
            latitude = parseFloat(geoaddress._lat);
        }

        return latitude;
    },
    getLongitude: (geoaddress) => {
        let longitude = 0;

        if (geoaddress.longitude) {
            longitude = parseFloat(geoaddress.longitude);
        } else if (geoaddress._longitude) {
            longitude = parseFloat(geoaddress._longitude);
        } else if (geoaddress._long) {
            longitude = parseFloat(geoaddress._long);
        }

        return longitude;
    },
    getGeoaddress: (geoaddress) => {
        // Note:
        // Since the geoaddress passed from the mobile is null,
        // we need to set the geopoint manually here in the website
        let latitude = 0.0;
        let longitude = 0.0;

        if (_.isEmpty(geoaddress)) {
            latitude = parseFloat(0);
            longitude = parseFloat(0);
        } else {
            if (geoaddress.latitude) {
                latitude = parseFloat(geoaddress.latitude);
            } else if (geoaddress._latitude) {
                latitude = parseFloat(geoaddress._latitude);
            } else if (geoaddress._lat) {
                latitude = parseFloat(geoaddress._lat);
            }

            if (geoaddress.longitude) {
                longitude = parseFloat(geoaddress.longitude);
            } else if (geoaddress._longitude) {
                longitude = parseFloat(geoaddress._longitude);
            } else if (geoaddress._long) {
                longitude = parseFloat(geoaddress._long);
            }
        }

        return new firebase.firestore.GeoPoint(parseFloat(latitude), parseFloat(longitude));
    },

    getAreaRadius: (radius) => {
        let number = 10;
        if (radius) {
            number = parseInt(radius);
        }
        return number;
    },

    getCoordinates: (latitude, longitude) => {
        if (latitude && longitude) {
            return "(" + latitude.toFixed(6) + " , " + longitude.toFixed(6) + ") ";
        }
        return "N/A";
    }
}
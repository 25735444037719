const moment = require('moment-timezone');
const defaultTimeZone = 'Asia/Manila';

// Set timezone globally
moment.tz.setDefault(defaultTimeZone);

const roundDate = (timeStamp) => {
	let d = new Date(timeStamp)
	d.setHours(0)
	d.setMinutes(0)
	d.setSeconds(0)
	d.setMilliseconds(0)
	return d.getTime()
};

const addDays = (date, days = 1) => {
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
};

const getDateRangeArray = (start, end, range = []) => {
	if (start > end) return range;
	const next = addDays(start, 1);
	return getDateRangeArray(next, end, [...range, start]);
};

export const DateUtil = {

	getAdustedDateInDays(date, days) {
		date.setDate(date.getDate() - days);
		return date.getTime();
	},

	getCurrentTimestamp() {
		return new Date().getTime();
	},

	startDateTimeStamp(date) {
		if (Object.prototype.toString.call(date) === '[object Date]') {
			return roundDate(date.getTime())
		} else {
			return roundDate(date);
		}
	},

	endDateTimeStamp(date) {
		let addOneDay = 60 * 60 * 24 * 1000
		if (Object.prototype.toString.call(date) === '[object Date]') {
			return roundDate(date.getTime()) + addOneDay - 1
		} else {
			return roundDate(date) + addOneDay - 1
		}
	},

	roundDate(timeStamp) {
		return roundDate(timeStamp);
	},

	getFormattedDate(dateStr) {
		if (dateStr) {
			let format = 'MMMM DD, YYYY'
			return moment(dateStr).tz('Asia/Manila').format(format)
		}
		return ''
	},

	getFormattedDateWithTime(dateStr) {
		if (dateStr) {
			let format = 'MMMM DD, YYYY, hh:mm A'
			return moment(dateStr).tz('Asia/Manila').format(format);
		}
		return '';
	},

	getDateInDDMMYYYYHHSSFormat(timeStamp) {
		let dateTime = new Date(timeStamp);
		return moment(dateTime).format('DDMMYYYYHHmmss');
	},

	getDateInMMDDYYYYFormat(timeStamp) {
		let dateTime = new Date(timeStamp);
		return moment(dateTime).format('MMDDYYYY');
	},

	getNoOfDays(dateFrom, dateTo) {
		// make sure that the inputs are in date format
		dateFrom = new Date(dateFrom);
		dateTo = new Date(dateTo);
		// get the difference in days
		let diff = Math.abs(dateFrom.getTime() - dateTo.getTime());
		let day = Math.ceil(diff / (1000 * 3600 * 24));
		return parseInt(day)
	},

	getCurrentMonth() {
		let d = new Date();
		let month = new Array();
		month[0] = "January";
		month[1] = "February";
		month[2] = "March";
		month[3] = "April";
		month[4] = "May";
		month[5] = "June";
		month[6] = "July";
		month[7] = "August";
		month[8] = "September";
		month[9] = "October";
		month[10] = "November";
		month[11] = "December";
		return month[d.getMonth()];
	},

	monthlyRangeTimestamp() {
		let date = new Date();
		let y = date.getFullYear();
		let m = date.getMonth();

		let firstDay = new Date();
		firstDay.setFullYear(y, m, 1);
		firstDay = roundDate(firstDay.getTime());

		let lastDay = new Date();
		lastDay.setFullYear(y, m + 1, 1);
		lastDay = roundDate(lastDay.getTime());
		lastDay = lastDay - 1;

		return {
			firstDay: firstDay,
			lastDay: lastDay
		};
	},

	// Param: dateStrng with "mm-dd-yyyy" format
	getTimestamp(dateString) {
		let strArr = dateString.split('-');
		let month = parseInt(strArr[0]) - 1;
		let day = parseInt(strArr[1]);
		let year = parseInt(strArr[2]);
		let date = new Date();
		date.setFullYear(year);
		date.setMonth(month);
		date.setDate(day);
		return date.getTime();
	},

	getDateRangeColumn(fromTimestamp, toTimestamp) {
		const range = getDateRangeArray(fromTimestamp, toTimestamp);
		return range.map(date => {
			return moment(date).format('MM/DD/YY')
		});
	}
}

import { db } from '@/config/firebase';
import _ from 'lodash';

export default {
    namespace: true,

    state: {
        dispatchAlertReports: {},
        currDispatchAlertReport: {},
    },

    mutations: {
        SET_ALL_DISPATCH_ALERT_REPORT(state, dispatchAlertReports) {
            state.dispatchAlertReports = dispatchAlertReports
        },
        SET_CURR_DISPATCH_ALERT_REPORT(state, currDispatchAlertReport) {
            state.currDispatchAlertReport = currDispatchAlertReport
        }
    },

    actions: {
        setAllDispatchAlertReports(vuexContext, dispatchAlertReports) {
            vuexContext.commit('SET_ALL_DISPATCH_ALERT_REPORT', dispatchAlertReports);
        },
        updateAllDispatchAlertReports(vuexContext, newDispatchAlertReports) {
            let dispatchAlertReports = vuexContext.getters.dispatchAlertReports;

            _.forEach(newDispatchAlertReports, (value, key) => {
                dispatchAlertReports[key] = value;
            });

            vuexContext.commit('SET_ALL_DISPATCH_ALERT_REPORT', dispatchAlertReports);
        }
    },

    getters: {
        dispatchAlertReports(state) {
            if (!_.isEmpty(state.dispatchAlertReports)) {
                return state.dispatchAlertReports;
            }
            return {};
        },
        currDispatchAlertReport(state) {
            if (!_.isEmpty(state.currDispatchAlertReport)) {
                return state.currDispatchAlertReport;
            }
            return {};
        },
    }
}